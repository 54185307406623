<template>
  <v-container fluid id="tnc">
    <v-container>
      <v-card class="py-2 py-md-7 px-2 px-md-6">
        <v-row justify="center" align="center">
          <v-col cols="12" sm="12" md="6">
            <h1 class="text-center increasedFontSize ml-md-9 ml-sm-0">
              About Us
            </h1>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-img
              height="200"
              contain
              src="@/assets/misc/undraw_development_re_g5hq.svg"
            ></v-img>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-12">
          <v-col cols="12" sm="12" md="9">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" sm="12" md="9" class="text-center">
            <span class="text-h4">coming soon...</span>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "AboutUs",
  metaInfo: {
    title: "Terms and Conditions",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "PlusMinus aka plusminusedu.com Terms and Conditions, privacy policy, refund policy and guidelines.",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content: "terms, conditions, privacy, guidelines, tnc, policy, refund",
      },
      {
        vmid: "robots",
        name: "robots",
        content: "index, follow",
      },
    ],
  },
};
</script>

<style>
.increasedFontSize {
  font-size: 3.98rem !important;
}
</style>